import { Container, Carousel, Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import coq from './static/media/coq-2.jpg';
import coq2 from './static/media/coq-1.jpg';

class NFTCarousel extends Component{

    render(){
        return(
            <Container>
                <Container>
                <Row>
                        <Col className='sneakpeeksCol'>
                        <Container className="mediaContainer NFT_Carousel sneakpeeksCol">
                        <img
                            className='sneakpeeks'
                            src={coq}
                            alt="patience"
                            />
                        </Container>
                        </Col>
                        <Col className='sneakpeeksCol'>
                        <Container className="mediaContainer NFT_Carousel sneakpeeksCol">
                        <img className='sneakpeeks'
                            src={coq2}
                            alt="patience"
                            />
                        </Container>
                        </Col>
                    </Row>
                    
                <Carousel className='carouselContent' fade indicators={false} controls={false} wrap={true} interval={4000}>
                        <Carousel.Item>

                        </Carousel.Item>
                        <Carousel.Item>
                   
                        </Carousel.Item>
                    </Carousel>  
                </Container>
            </Container>
            
        )
    }
}

export default (NFTCarousel);