import React, { Component } from 'react';
import Navbar from './layout/Navbar';



export default class Header extends Component {

  render() {
    return (
        <Navbar/>
        )
  }
}