import React, { Component } from 'react';
import  WelcomeContainer from './containers/welcomeContainer';
import  NFTCarousel from './containers/nft_carousel';
import ProjectIntro from './containers/introduction';
import Separator from './containers/separator';
import Roadmap from './containers/roadmap';
import Team from './containers/team';
//import TweetContainer  from './containers/lastTweets';


class Body extends Component {
   constructor(props) {
      super(props)
      this.state = { matches: window.matchMedia("(max-width: 800px)").matches };
  }

  componentDidMount() {
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(max-width: 800px)").addEventListener('change', handler);
  }

 render(){
    return(
        <div>
           <WelcomeContainer/>
           <NFTCarousel/>
           <ProjectIntro/>
           <Roadmap/>
           <Team/>          
           <Separator/>
        </div>
    )
 }

}
export default (Body);