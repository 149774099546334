import React, { Component } from 'react';
import {Link} from 'react-scroll';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from './logo.png';
import WalletConnect from './walletConnect';


const MenuItems= [
    {
        title: "Home",
        cName: "nav-item",
        id: "welcome"
    }, 
    {
        title: "Introduction",
        cName: "nav-item",
        id: "introduction"
    },
    {
        title: "Roadmap",
        cName: "nav-item",
        id: "roadmap"
    },
    {
        title: "Team",
        cName: "nav-item",
        id: "team"
    }

];



export default class MyNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {width: props.width};
      }
    
      componentWillMount(){
        this.setState({width: window.innerWidth + 'px'});
      }

    render(){
        return(
            
            <Navbar bg="dark" variant="dark">
                <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                {MenuItems.map((item, idx) => {
                        return( 
                            <Nav.Link  className={item.cName} key={idx}>
                                <Link
                                activeClass='active'
                                to={item.id}
                                spy={true}
                                smooth={true}
                                exact= {true}
                                offset={0}
                                duration={1000}
                                >
                                    {item.title}
                                </Link>
                            </Nav.Link >
                              )
                    })}
                <Nav>
                    <Nav.Link className='nav-item nav-icon' href='https://twitter.com/ChickenFCnft'>
                        <a className="logo-item fab fa-twitter fa-2x" ></a>
                    </Nav.Link>
                    <Nav.Link className='nav-item nav-icon' href='https://discord.gg/chickensfightclub'>
                        <a className=" logo-item fab fa-discord fa-2x" ></a>
                    </Nav.Link>
                    <Nav.Link className='nav-item nav-icon' href='https://www.instagram.com/chickenfighterclub/'>
                        <a className=" logo-item fab fa-instagram fa-2x"></a>
                    </Nav.Link>
                </Nav>


                </Nav>
                </Navbar.Collapse>
                <div className='nav-logo'><a href='/' >
                    <img
                    src={logo}
                    height="180"
                    width={375}
                    className="d-inline-block align-center"
                    alt="React Bootstrap logo"
                /></a>
                </div>
                
                
                
                </Container>
            </Navbar>



        )
    }
}

/*
            <nav className="nav" id="navbar">
                <div className="nav-content">
                    
                    <ul className="nav-items">
                    
                    </ul>
                </div>
            </nav>
            */