import { Container, Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import introText from './texts/introductionText';
import rarityText from './texts/rarity';
import video from './static/media/poison_egg_3.mp4';
import video2 from './static/media/magma_egg_1_2_1.mp4';




class ProjectIntro extends Component{

    render(){
        return(
            <div id="introduction" className='introContainer'>   
                <h2>Introduction</h2>         
                <Container>
                    <Row>
                        <Col className='sneakpeeksCol'>
                        <Container className="mediaContainer NFT_Carousel">
                        <video autoPlay={true} loop={true} muted > 
                            <source src={video2} type='video/mp4'/>
                        </video>
                        </Container>
                        </Col>
                        <Col className='sneakpeeksCol'>
                        <Container className="mediaContainer NFT_Carousel">
                        <video autoPlay={true} loop={true} muted > 
                            <source src={video} type='video/mp4'/>
                        </video>
                        </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container>
                                <p>
                                 {introText}
                                </p>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Container>
                        <h3>Rarity</h3>  
                            <p>
                            {rarityText}   
                            </p>
                        </Container>
                    </Row>
                </Container> 
            </div>
        )
    }
}

export default (ProjectIntro);