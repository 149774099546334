import { Container, Row, Col } from 'react-bootstrap';
import { Component } from 'react';

class WelcomeContainer extends Component{

    render(){
        return(
                <Container id="welcome">
                    <Row>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Chickens are coming soon!</h2>
                        </Col>
                    </Row>
                </Container>
        )
    }
}

export default (WelcomeContainer);