import React, { Component, useState, useEffect } from 'react';
import {animateScroll as scroll} from 'react-scroll';

//components
import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';
//import MyNavbar from './components/layout/navbar';


//LAYOUT
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './App2.css';
import "../node_modules/video-react/dist/video-react.css";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

// Constants

const App = () =>{
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);


  const scrollToTop = () => {
    scroll.scrollToTop();
  };

    return(
      <div className="App">        
        <Header/>
        <Body/>
        {showButton && (
          <button onClick={scrollToTop} className="back-to-top">
            <BsFillArrowUpCircleFill/>
          </button>
        )}
        <Footer/>
      </div>
    )
  
}

export default App;