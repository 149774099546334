import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

//document.addEventListener('contextmenu', event => event.preventDefault());

ReactDOM.render(<App />, document.getElementById('root'));

