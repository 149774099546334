import { Container, Row } from 'react-bootstrap';
import { Component } from 'react';
import {
    MDBFooter,
    MDBContainer,
    MDBIcon
  } from 'mdb-react-ui-kit';
  import logo from './layout/logo.png';



class Footer extends Component{

    render(){
        return(
            <Container>
                <MDBFooter className='text-center text-white' style={{ backgroundColor: '#353535' }}>
                    <MDBContainer className='pt-4'>
                        <Row>
                        <a href='https://twitter.com/ChickenFCnft'>
                        <img
                            src={logo}       
                            alt="React Bootstrap logo"
                            style={{ margin: 'auto', width:'100%', height:'100%'}}
                        /></a>
                        
                        </Row>
 
                    </MDBContainer>

                    <div className='text-center text-dark p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        © 2022 Copyright: 
                        <a className='text-dark' href='/'>
                          Chicken Fight Club
                        </a>
                    </div>
                </MDBFooter>
            </Container>
        )
    }
}




export default (Footer);